import { render, staticRenderFns } from "./AssetType.vue?vue&type=template&id=1bfcc7c2&scoped=true&"
import script from "./AssetType.vue?vue&type=script&lang=js&"
export * from "./AssetType.vue?vue&type=script&lang=js&"
import style0 from "./AssetType.vue?vue&type=style&index=0&id=1bfcc7c2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bfcc7c2",
  null
  
)

export default component.exports